import {
  mdiArrowRightCircleOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiCurrencyUsdCircleOutline,
  mdiEyePlus,
  mdiUpload,
} from "@mdi/js";
import { CryptoModel } from "@/models/cryptoModel";
import operacionesModule from "@/store/modules/operacionesModule";
import snackbarModule from "@/store/modules/snackbarModule";
import errorSnackbarModule from "@/store/modules/snackbarModule";
import spinnerModule from "@/store/modules/spinnerModule";
import { ApiErrorResponse, ApiSistemaPagos, ApiSasToken, AxiosHttpClient } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import {
  EstatusOperacion,
  EstatusPago,
  Operacion,
  OperacionExtendida,
  OperacionRelacion,
  UpdateEstatusOperacion,
} from "apd.sistemapagos.models";
import { BlobServiceClient } from "@azure/storage-blob";
import Vue from "vue";

interface Datos {
  id: string;
  folioAPD?: string;
  cuenta?: string;
  telefono?: string;
  email?: string;
  nombre?: string;
  importe: number;
  fecha: string;
  banco?: string;
}
export default Vue.extend({
  name: "NoDispensadoComponent",
  data() {
    return {
      Icons: {
        cash: mdiCurrencyUsdCircleOutline,
        close: mdiClose,
        arrow: mdiArrowRightCircleOutline,
        upload: mdiUpload,
        info: mdiEyePlus,
        expand: mdiChevronDown,
        hide: mdiChevronUp,
      },
      items: ["Por pagar", "Procesados"],
      dialog: false,
      tab: null,
      operaciones: [] as Datos[],
      crypto: [] as CryptoModel[],
      loading: false,
      headers: [
        { id: 1, text: "FolioAPD", value: "folioAPD", align: "start" },
        { id: 2, text: "Cuenta", value: "cuenta", align: "start" },
        { id: 3, text: "Telefono", value: "telefono", align: "start" },
        { id: 4, text: "Email", value: "email", align: "start" },
        { id: 5, text: "Nombre", value: "nombre", align: "start" },
        { id: 6, text: "Importe", value: "importe", align: "end" },
        { id: 7, text: "", value: "", align: "start", sortable: false },
      ],
      data: [] as Datos[],
      dialogInfo: false,
      selectedCnd: {} as OperacionExtendida,
      kiosko: "",
      infoCnd: true,
      dialogConfirmacion: false,
      operacionPricipal: {} as Operacion,
      cndPrincipal: 0,
      selectedFile: {} as File,
      dialogSubir: false,
      sasToken: "",
    };
  },
  computed: {
    estatusOperacionToString() {
      return (value: number): string => {
        return EstatusOperacion[value];
      };
    },
    show() {
      if (this.infoCnd) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async uploadFile() {
      const clientST = new AxiosHttpClient(getUrl(ApiList.SasToken, this.$store.getters.appMode));
      clientST.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiST = new ApiSasToken(clientST);
      this.sasToken = apiST.GetSasToken();

      const blobSasUrl = "https://transferencias.sos.kioskos.net/" + this.sasToken;
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient("transferencias");
      const name = (this.operacionPricipal.folioAPD + "_" + this.selectedCnd.folioAPD + ".pdf").replace(/\s/g, "");
      if (!this.selectedFile.name.includes(".pdf")) {
        errorSnackbarModule.Show("Debe ser .pdf");
        this.selectedFile = {} as File;
        return;
      }
      try {
        const promises = [];
        const blockBlobClient = containerClient.getBlockBlobClient(name);
        promises.push(blockBlobClient.uploadBrowserData(this.selectedFile));

        await Promise.all(promises);
        snackbarModule.ShowSuccess("El comprobante se subio exitosamente.");
        this.dialogConfirmacion = true;
        this.selectedFile = {} as File;
      } catch (error) {
        errorSnackbarModule.Show(error.message);
      }
    },
    async load() {
      spinnerModule.Show();
      await operacionesModule.ResetFiltroNoDispensado();
      await this.change();
      this.dialog = true;
      spinnerModule.Hide();
    },
    async change() {
      this.loading = true;
      this.operaciones = [];
      const data: Datos[] = [];
      await operacionesModule.ResetFiltroNoDispensado();

      if (this.tab == 0 || this.tab == null) {
        this.headers[6].text = "";
        operacionesModule.FiltroNoDispensado.estatusOperacion = [EstatusOperacion.NoDispensadoPorPagar];
      }
      if (this.tab == 1) {
        this.headers[6].text = "Fecha Procesamiento";
        this.headers[6].value = "fecha";
        this.headers[6].sortable = true;
        operacionesModule.FiltroNoDispensado.estatusOperacion = [EstatusOperacion.NoDispensadoPagado];
      }

      await operacionesModule.LoadOperacionesNoDispensadas();
      await operacionesModule.noDispensadas.forEach((operacion) => {
        let cuenta = operacion.numeroCuenta;
        if (cuenta != undefined) {
          cuenta = "**** **** **** " + operacion.numeroCuenta.substring(operacion.numeroCuenta.length - 4);
        }

        data.push({
          id: operacion.id,
          folioAPD: operacion.folioAPD,
          nombre: operacion.nombreCuenta,
          cuenta: cuenta,
          banco: operacion.banco,
          telefono: operacion.telefono,
          email: operacion.email,
          importe: operacion.egresos,
          fecha: operacion.updated,
        });
      });
      this.operaciones = data.sort((a, b) => {
        if (a.fecha != null && b.fecha != null) {
          if (a.fecha > b.fecha) {
            return -1;
          }
          if (a.fecha < b.fecha) {
            return 1;
          }
        }
        return 0;
      });

      this.loading = false;
    },
    async procesar(id: string) {
      this.loading = true;
      const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      clientSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP = new ApiSistemaPagos(clientSP);
      const update = {
        idOperacion: id,
        estatusOperacion: EstatusOperacion.NoDispensadoPagado,
      } as UpdateEstatusOperacion;

      await apiSP
        .ActualizarEstatusOperacion(id, update)
        .then((e) => {
          if (e) {
            snackbarModule.ShowSuccess("Operacion procesada");
          }
        })
        .catch((error) => {
          const x = error as ApiErrorResponse;
          x.apiError.mensajeUsuario;
          snackbarModule.Show(x.apiError.mensajeUsuario);
        });
      await operacionesModule.ResetFiltroNoDispensado();
      operacionesModule.FiltroNoDispensado.estatusOperacion = [EstatusOperacion.NoDispensadoPagado];
      await this.change();
      this.dialogConfirmacion = false;
      this.dialogInfo = false;
      this.loading = false;
    },
    async info(id: string) {
      this.loading = true;
      this.selectedCnd = (await operacionesModule.noDispensadas.find((d) => d.id == id)) as OperacionExtendida;

      const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      clientSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP = new ApiSistemaPagos(clientSP);

      await apiSP
        .ObtenerOperacionPrincipal(id)
        .then((e) => {
          const operacionP = e as unknown as OperacionRelacion;
          this.operacionPricipal = operacionP.operacionPrincipal;
          if (this.operacionPricipal.pagos != undefined) {
            this.cndPrincipal = this.operacionPricipal.pagos
              .filter((p) => p.estatusPago == EstatusPago.NoDispensado)
              .reduce((p, i) => p + Number(i.monto), 0);
          }
        })
        .catch(() => {
          this.operacionPricipal = {} as Operacion;
          this.cndPrincipal = 0;
        });

      this.dialogInfo = true;

      this.loading = false;
    },
  },
});
